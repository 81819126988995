import { useState, useEffect } from 'react';

const requestHeaders = {};

const requestOptions = {
  method: 'GET',
  headers: requestHeaders,
};

export const useGetFormData = formId => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/forms/${formId}.json`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(e => {
        setError(e?.message || 'Oops! Noe gikk galt.');
        setLoading(false);
      });
  }, [formId]);

  return { data, loading, error };
};
