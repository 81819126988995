import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export default styled(Grid).withConfig({
  shouldForwardProp: prop => !['bgImage'].includes(prop),
})`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-image: ${({ bgImage }) => `url(${bgImage.url})`};
`;
