import React from 'react';
import PropTypes from 'prop-types';
import CareerContactForm from '../CareerContactForm';
import CTAContactForm from '../CTAContactForm';
import SubscribeForm from '../SubscribeForm';
import ContactForm from '../ContactForm';

const FormHandler = props => {
  switch (props.form.handle) {
    case 'careerListingsContactForm':
      return <CareerContactForm {...props} />;
    case 'ctaContactForm':
      return <CTAContactForm {...props} />;
    case 'subscribeForm':
      return <SubscribeForm {...props} />;
    case 'contactForm':
      return <ContactForm {...props} />;
    default:
      return null;
  }
};

export default FormHandler;

FormHandler.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    handle: PropTypes.string,
  }).isRequired,
};
