import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { Grid, Button, Box, useMediaQuery } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { handleSproutFieldType } from '@helpers';

const CareerContactForm = ({ handleSubmit, isSubmitting, handleReset, formData }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignItems='center' spacing={isSmall ? 3 : 5}>
        {formData.fields.map(field => {
          if (field.type === 'hidden') return null;

          return (
            <Grid item xs={12} md={field.type === 'paragraph' ? 12 : 6} key={field.attributes.id}>
              <Field
                component={TextField}
                name={field.attributes.handle}
                label={field.attributes.name}
                placeholder={field.attributes.placeholder}
                required={field.attributes.required === '1'}
                type={handleSproutFieldType(field.type)}
                multiline={field.type === 'paragraph'}
                rows={field.type === 'paragraph' ? 7 : 1}
                fullWidth
              />
            </Grid>
          );
        })}
      </Grid>
      <Box mt={{ xs: 3, md: 4 }}>
        <Button type='submit' fullWidth variant='contained' disabled={isSubmitting}>
          Send søknad
        </Button>
      </Box>
      <Box mt={{ xs: 1, md: 2 }}>
        <Button type='reset' fullWidth size='small' onClick={handleReset} disabled={isSubmitting}>
          Tilbakestill Skjema
        </Button>
      </Box>
    </Form>
  );
};

export default CareerContactForm;

CareerContactForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
