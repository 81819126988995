import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Snackbar, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useGetFormData, useSubmitFormData } from '@hooks/forms';
import { handleSproutFormValidationSchema } from '@helpers';
import CareerContactForm from './CareerContactForm.component';

const CareerContactFormContainer = ({ form, careerListingTitle }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { data, loading, error } = useGetFormData(form.id);
  const submitForm = useSubmitFormData();

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  }, []);

  const handleOnSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      submitForm(values)
        .then(() => {
          setSubmitting(false);
          resetForm();
          setSnackbarMessage('Suksess');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch(error => {
          setSubmitting(false);
          setSnackbarMessage(error?.message || 'Oops! Noe gikk galt.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    },
    [submitForm]
  );

  const initialValues = useMemo(() => {
    if (!data) return {};

    return {
      action: data.action,
      handle: data.handle,
      ...data.fields.reduce((acc, cur) => {
        acc[cur.attributes.handle] =
          cur.attributes.handle === 'careerListing' ? careerListingTitle : '';
        return acc;
      }, {}),
    };
  }, [data, careerListingTitle]);

  if (loading)
    return (
      <Box textAlign='center'>
        <CircularProgress color='primary' />
      </Box>
    );

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Alert severity='error'>{error}</Alert>
      </Box>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={handleSproutFormValidationSchema(data.fields)}
        onSubmit={handleOnSubmit}
      >
        {props => <CareerContactForm {...props} formData={data} />}
      </Formik>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CareerContactFormContainer;

CareerContactFormContainer.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    handle: PropTypes.string.isRequired,
  }).isRequired,
  careerListingTitle: PropTypes.string.isRequired,
};
