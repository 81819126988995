import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { Grid, Button, Box } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { handleSproutFieldType } from '@helpers';

const CTAContactForm = ({ handleSubmit, isSubmitting, formData, bookCta }) => (
  <Form onSubmit={handleSubmit}>
    <Grid container alignItems='center' spacing={4}>
      {formData.fields.map(field => {
        if (field.type === 'hidden' || field.type === 'users') return null;

        return (
          <Grid item xs={12} key={field.attributes.id}>
            <Field
              component={TextField}
              name={field.attributes.handle}
              placeholder={field.attributes.name}
              required={field.attributes.required === '1'}
              type={handleSproutFieldType(field.type)}
              fullWidth
            />
          </Grid>
        );
      })}
    </Grid>
    <Box mt={{ xs: 3, md: 4 }}>
      <Button type='submit' fullWidth variant='contained' disabled={isSubmitting}>
        {bookCta}
      </Button>
    </Box>
  </Form>
);

export default CTAContactForm;

CTAContactForm.propTypes = {
  formData: PropTypes.object.isRequired,
  bookCta: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
