import styled from 'styled-components';
import { Form } from 'formik';

export default styled(Form)`
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: ${({ headerAbsolute, theme }) =>
    `calc(${theme.spacing(18)}px + ${theme.header.height}px)`};
  padding-bottom: ${({ theme }) => theme.spacing(29)}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: ${({ headerAbsolute, theme }) =>
      `calc(${theme.spacing(6)}px + ${theme.header.height}px)`};
    padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  }

  .MuiFormHelperText-root {
    position: static;

    * {
      font-size: 0.875rem;
    }
  }
`;
