import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, getIn } from 'formik';
import { Grid, Button, Box, MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { handleSproutFieldType } from '@helpers';
import { SelectField } from '@c/forms/fields';

const ContactForm = ({
  handleSubmit,
  isSubmitting,
  formData,
  isValid,
  touched,
  errors,
  contactFormCta,
}) => {
  const getError = useCallback(fieldName => getIn(touched, fieldName) && getIn(errors, fieldName), [
    errors,
    touched,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignItems='center' spacing={4}>
        {formData.fields.map(field => {
          switch (field.type) {
            case 'hidden':
              return null;
            case 'dropdown':
              return (
                <Grid item xs={12} key={field.attributes.id}>
                  <SelectField
                    id={field.attributes.handle}
                    name={field.attributes.handle}
                    label={field.attributes.name}
                    error={getError(field.attributes.handle)}
                    options={field.attributes.options.map(option => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    required={field.attributes.required === '1'}
                    defaultValue=''
                  />
                </Grid>
              );
            default:
              return (
                <Grid item xs={12} key={field.attributes.id}>
                  <Field
                    component={TextField}
                    name={field.attributes.handle}
                    label={field.attributes.name}
                    placeholder={field.attributes.placeholder}
                    required={field.attributes.required === '1'}
                    type={handleSproutFieldType(field.type)}
                    multiline={field.type === 'paragraph'}
                    rows={field.type === 'paragraph' ? 7 : 1}
                    fullWidth
                  />
                </Grid>
              );
          }
        })}
      </Grid>
      <Box mt={{ xs: 3, md: 4 }}>
        <Button type='submit' fullWidth variant='contained' disabled={!isValid || isSubmitting}>
          {contactFormCta}
        </Button>
      </Box>
    </Form>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
