import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { Grid, Button, Box, Hidden } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import ChevronRightIcon from '@assets/chevron-right.svg';
import ImageGrid from './ImageGrid';
import StyledSubscribeForm from './SubscribeForm.styled';

const SubscribeForm = ({
  handleSubmit,
  isSubmitting,
  intro,
  fieldName,
  fieldHelperText,
  subscribeCta,
  image,
  touched,
  errors,
  ...props
}) => {
  const emailError = useMemo(() => getIn(touched, 'email') && getIn(errors, 'email'), [
    errors,
    touched,
  ]);

  return (
    <Grid container justify='space-between'>
      <Grid item xs={12} md={5}>
        <StyledSubscribeForm onSubmit={handleSubmit}>
          <Box dangerouslySetInnerHTML={{ __html: intro }} />

          <Box my={{ xs: 5, md: 8 }}>
            <Field
              component={TextField}
              name='email'
              placeholder={fieldName}
              helperText={
                emailError ||
                (fieldHelperText ? (
                  <div dangerouslySetInnerHTML={{ __html: fieldHelperText }} />
                ) : null)
              }
              FormHelperTextProps={{
                component: 'div',
              }}
              required
              type='email'
              inputProps={{
                'aria-label': 'email',
              }}
            />
          </Box>

          <Button
            type='submit'
            variant='outlined'
            size='small'
            endIcon={<ChevronRightIcon />}
            disabled={isSubmitting}
          >
            {subscribeCta}
          </Button>
        </StyledSubscribeForm>
      </Grid>

      <Hidden smDown>
        <ImageGrid item md={6} bgImage={image[0]} />
      </Hidden>
    </Grid>
  );
};

export default SubscribeForm;

SubscribeForm.propTypes = {
  intro: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldHelperText: PropTypes.string,
  subscribeCta: PropTypes.string.isRequired,
  image: PropTypes.array.isRequired,
};
