import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { subscribeFormValidationSchema } from './SubscribeForm.validation';
import SubscribeForm from './SubscribeForm.component';

const SubscribeFormContainer = props => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarDuration, setSnackbarDuration] = useState(3000);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  }, []);

  const handleOnSubmit = useCallback(({ email }, { setSubmitting, resetForm }) => {
    addToMailchimp(email)
      .then(data => {
        setSubmitting(false);
        if (data.result === 'success') {
          setSnackbarDuration(3000);
          setSnackbarMessage(data?.msg || 'Takk for din påmelding til nyhetsbrevet vårt!');
          setSnackbarSeverity('success');
          resetForm();
        } else {
          setSnackbarDuration(null);
          setSnackbarMessage(data?.msg || 'Oops! Noe gikk galt.');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      })
      .catch(error => {
        setSubmitting(false);
        setSnackbarDuration(3000);
        setSnackbarMessage(error?.message || 'Oops! Noe gikk galt.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  }, []);

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={subscribeFormValidationSchema}
        onSubmit={handleOnSubmit}
      >
        {formikProps => <SubscribeForm {...props} {...formikProps} />}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarDuration}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          <div dangerouslySetInnerHTML={{ __html: snackbarMessage }} />
        </Alert>
      </Snackbar>
    </>
  );
};

export default SubscribeFormContainer;
