import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { Select } from 'formik-material-ui';
import ChevronDownIcon from '@assets/chevron-down.svg';
import StyledSelect from './Select.styled';

const SelectField = ({ error, options, label, name, id, helperText, disabled, ...props }) => (
  <StyledSelect fullWidth variant='outlined' error={!!error}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Field
      disabled={disabled}
      component={Select}
      id={id}
      name={name}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      IconComponent={() => (
        <ChevronDownIcon className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
      )}
      {...props}
    >
      {options}
    </Field>
    {(!!error || !!helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
  </StyledSelect>
);

export default SelectField;

SelectField.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.node.isRequired),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  error: null,
  options: [],
  helperText: null,
  disabled: false,
};
