import { handleFormData } from '@helpers';

export const useSubmitFormData = () => ({ action, ...variables }) => {
  const requestHeaders = {
    Accept: 'application/json',
  };

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: handleFormData(variables),
  };

  return fetch(`${process.env.GATSBY_BACKEND_URL}?action=${action}`, requestOptions)
    .then(res => {
      // Success status lies between 200 to 300
      if (res.status >= 200 && res.status < 300) {
        return res;
      }

      return Promise.reject(res.statusText);
    })
    .then(res => res.json())
    .then(res => {
      if (res?.error) return Promise.reject(res?.error);

      return res;
    });
};
