import styled from 'styled-components';
import { transparentize } from 'polished';
import { FormControl } from '@material-ui/core';

export default styled(FormControl)`
  .MuiInputLabel-outlined {
    transform: translate(0, ${({ theme }) => theme.spacing(2)}px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(0, ${({ theme }) => theme.spacing(-3.5)}px) scale(0.75);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .MuiOutlinedInput-root {
    svg {
      height: 14px;
      width: 14px;
      right: ${({ theme }) => theme.spacing(2)}px;
      top: calc(50% - 6px);

      path {
        stroke: ${({ theme }) => transparentize(0.32, theme.colors.dark)};
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }) => transparentize(0.76, theme.colors.dark)};
      }
    }

    &.Mui-error,
    &.Mui-error.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-color: ${({ theme }) => theme.palette.error.main};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.palette.error.main};
        }
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-color: ${({ theme }) => theme.colors.dark};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.colors.dark};
        }
      }
    }
  }
`;
